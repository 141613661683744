<template>
  <div class="analysis-box">
      <div class="answer-item">
        <div class="title">【答案】</div>
        <div v-if="value.answer" class="name">
          <mathpad :value="value.answer" />
        </div>
        <div class="title" v-else>暂无</div>
      </div>
      <div class="analysis-item">
        <div class="title">【解析】</div>
        <div v-if="value.analysis" class="name">
          <mathpad :value="value.analysis" />
        </div>
        <div class="title" v-else>暂无</div>
      </div>
      <div class="knowPoint-item">
        <div class="title">【知识点】</div>
        <div class="know-name" v-if="value.knowledgeNames && value.knowledgeNames.length">
          <div v-for="(item, index) in value.knowledgeNames" :key="index">{{ item }}</div>
        </div>
        <div class="title" v-else>暂无</div>
      </div>
      <div class="accomplishment-item">
        <div class="title">【核心素养】</div>
        <div class="accomp-name" v-if="value.keyAccomplishNames && value.keyAccomplishNames.length">
          <div v-for="(item, index) in value.keyAccomplishNames" :key="index">{{ item }}</div>
        </div>
        <div v-else class="title">暂无</div>
      </div>
    </div>
</template>

<script>
import mathpad from './Mathpad.vue'
export default {
  components: {
    mathpad
  },
  props: {
    value: {
      type: Object,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.analysis-box{
  margin-top: 20px;
  .answer-item,.analysis-item,.knowPoint-item,.accomplishment-item{
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 20px;
    .title{
      white-space: nowrap;
      padding: 4px 0;
    }
    .name{
      padding: 4px 0;
      font-weight: normal;
    }
    .know-name,.accomp-name{
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      div{
        margin: 0 10px 5px 0;
        padding: 4px 10px;
        border-radius: 4px;
        background-color: #F5FFFC;
        color: #04D7AB;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
  .accomplishment-item{
    margin-bottom: 0;
  }
}
</style>
