<template>
  <el-select v-model="data" multiple @change="change">
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectKeyAccomplishment',
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    options: {
      type: Array,
      default: undefined
    }
  },
  data () {
    return {
      loading: false,
      data: [],
      list: [],
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.data = val
          ? val.map(m => m.keyAccomplishmentId)
          : []
      }
    },
  },
  methods: {
    change () {
      const d = this.data.map(m => ({ keyAccomplishmentId: m }))
      this.$emit('input', d)
    }
  }
}
</script>
