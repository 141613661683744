<template>
  <div class="ui-mathpad" ref="wrapper" :class="{ done: !loading }">
    <div ref="mathpad" v-html="value" />
  </div>
</template>

<script>
export default {
  name: 'UiMath',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: true
    }
  },
  watch: {
    value: {
      handler () {
        this.loading = true
        this.$nextTick(() => {
          const dom = this.$refs.mathpad
          if (!dom) {
            console.log('Cannot read properties of undefined (reading "offsetHeight")')
            return
          }
          this.loading = false
          window.MathQueue([dom])
        })
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-mathpad {
  transition: all 0.3s;
  opacity: 0;
  max-width: 800px;
  word-wrap: break-word;

  &.done {
    opacity: 1;
  }

  /deep/ img {
    max-width: 100%;
    max-height: 800px;
  }
}
</style>
