<template>
  <div v-loading.fullscreen="loading">
    <el-button plain @click="dlgShow">选择知识点</el-button>
    <div v-if="list.length" class="result">
      <div class="result-label">已选：</div>
      <ul class="result-list">
        <li v-for="(item, index) in checkedNodes" :key="item.id">
          <i class="el-button el-button--text el-icon-delete" @click="remove(item, index)"></i>
          <span>{{ item.title }}</span>
        </li>
      </ul>
    </div>

    <el-dialog
      :visible.sync="visible"
      :append-to-body="true"
      :close-on-click-modal="false"
      destroy-on-close
      width="500px"
    >
      <el-input
        class="dlg-search"
        placeholder="输入关键字进行过滤"
        :maxlength="30"
        v-model.trim="knowledgeText">
      </el-input>
      <el-tree
        ref="tree"
        class="dlg-main"
        check-strictly
        node-key="id"
        show-checkbox
        :props="{ children: 'children', label: 'title', value: 'id' }"
        :data="treeOptions"
        :default-expanded-keys="list"
        @node-click="nodeClick"
        :filter-node-method="filterKnowledge"
      >
        <span class="custom-tree-node" slot-scope="{ node }">
          {{ node.label }}
        </span>
      </el-tree>
      <div class="dlg-footer">
        <el-button style="width: 80px" @click="dlgClose">取消</el-button>
        <el-button style="width: 80px" type="primary" @click="dlgSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getKnowledgePointList } from '@/api/knowledge'

export default {
  name: 'SelectKnowledge',
  props: {
    value: {
      type: Array,
      default: null
    },
    // chapterId: {
    //   type: Number,
    //   default: undefined
    // },
    options: {
      type: Array,
      default: undefined
    },
    subsectionSubjectId: {
      type: Number,
      default: undefined
    },
    beforeChange: {
      type: Function,
      default: function (node, index, next) {
        this.$confirm('确认更改知识点')
          .then(next)
          .catch(e => {})
      }
    }
  },
  data () {
    return {
      loading: false,
      list: [],
      checkedNodes: [],
      // expandedKeys: [],

      visible: false,
      treeOptions: undefined,
      knowledgeText: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        if (val) {
          this.list = val.map(m => m.knowledgeId)
          // this.list = [503878]
          if (this.treeOptions) {
            this.getNodes()
          }
        }
      }
    },
    options: {
      immediate: true,
      handler (val) {
        if (val) {
          this.treeOptions = val
          if (this.value) {
            this.getNodes()
          }
        }
      }
    },
    knowledgeText (val) {
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    filterKnowledge (value, data, node) {
      if (!value) return true
      let parentNode = node.parent
      let labels = [node.label]
      let level = 1
      while (level < node.level) {
        labels = [...labels, parentNode.label]
        parentNode = parentNode.parent
        level++
      }
      return labels.some(label => label.indexOf(value) !== -1)
    },
    // 知识点选项
    loadOptions (subsectionSubjectId) {
      this.loading = true
      return getKnowledgePointList(subsectionSubjectId).finally(() => {
        this.loading = false
      }).then(res => {
        if (res.code === 0) {
          this.treeOptions = this.disableTreeData(res.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 非最后一层都不可选中
    disableTreeData (list) {
      list.forEach(item => {
        if (item.children && item.children.length > 0) {
          item.disabled = true
          this.disableTreeData(item.children)
        }
      })
      return list
    },

    nodeClick (data, node, vm) {
      if (node.isLeaf) {
        node.checked = !node.checked
      }
    },

    getNodes () {
      const result = []
      this.list.forEach(m => {
        const node = this.findNode(this.treeOptions, m, 'id')
        result.push(node || { id: m, title: '-' })
      })
      this.checkedNodes = result
    },

    findNode (treeData, val, key) {
      for (let i = 0; i < treeData.length; i++) {
        const m = treeData[i]
        if (m[key] === val) {
          return m
        }
        if (m.children) {
          const r = this.findNode(m.children, val, key)
          if (r) return r
        }
      }
    },

    // 删除已选
    remove (node, index) {
      this.beforeChange(node, index, () => {
        const idList = this.checkedNodes.reduce((prev, m, n) => {
          if (index !== n) {
            prev.push({ knowledgeId: m.id })
          }
          return prev
        }, [])
        this.$emit('input', idList)
      })
    },

    dlgShow () {
      this.knowledgeText = null
      if (!this.subsectionSubjectId) {
        return this.$message.error('未指定学科学段')
      }
      if (!this.treeOptions) {
        this.loadOptions(this.subsectionSubjectId)
      }
      this.visible = true
      this.$nextTick(() => {
        if (this.$refs.tree) {
          this.$refs.tree.setCheckedKeys(this.list)
        }
      })
    },

    dlgClose () {
      this.visible = false
    },

    dlgSubmit () {
      const d = this.$refs.tree.getCheckedNodes()
      this.checkedNodes = d
      this.$emit('input', d.map(m => ({ knowledgeId: m.id })))
      this.dlgClose()
    },
  }
}
</script>

<style lang="scss">
.dlg-search {
  display: block;
  margin: -16px 0 16px;
}
.result {
  min-height: 32px;

  &-label {
    float: left;
  }

  &-list {
    margin-left: 3em;

    .el-button {
      margin-right: 5px;
      padding: 0;
    }
  }
}

.dlg-main {
  height: 300px;
  margin-bottom: 20px;
  overflow-y: auto;
}
</style>
