<template>
  <div class="page">
    <div class="headerTop">
      <div class="qns">题目</div>
      <div class="fun" v-if="sortMode">
        <div class="tip">请拖动到正确顺序后保存</div>
        <el-button size="small" type="primary" @click="handleSort('submit')">保存</el-button>
        <el-button size="small" type="default" @click="sortMode = false">取消</el-button>
      </div>
      <div class="fun" v-else>
        <el-button size="small" type="primary" @click="handleSort()">排序</el-button>
        <el-button size="small" type="primary" @click="branchDrawCoord">批量画坐标</el-button>
        <!--<el-button size="small" type="primary" @click="branchErrorCorrection">批量纠错</el-button>-->
        <el-dropdown style="margin-left: 10px" placement="bottom" trigger="hover" @command="handleCommand">
          <el-button size="small" type="primary">新增</el-button>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="add">
              <el-upload
                class="upload-demo"
                id="input-file-upload"
                action=""
                :multiple="false"
                :show-file-list="false"
                :before-upload="beforeUpload"
                :on-change="handleChange"
                disabled
                accept=".doc,.docx">
                word导入
              </el-upload>
            </el-dropdown-item> -->
            <el-dropdown-item command="img">
              <span>图片识别</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div style="margin-bottom: 20px;">
      <span class="label">审核状态：</span>
      <el-radio-group v-model="filterParams" size="mini" :disabled="sortMode">
        <el-radio label="">不限 ({{ list | amount }})</el-radio>
        <el-radio label="TO_BE_PROCESSED">待审核 ({{ list | amount('TO_BE_PROCESSED') }})</el-radio>
        <el-radio label="AUDIT_FAILED">审核失败({{ list | amount('AUDIT_FAILED') }})</el-radio>
        <el-radio label="COMPLETED">审核通过 ({{ list | amount('COMPLETED') }})</el-radio>
        <el-radio label="NO_PLAY_LABEL">未打标签 ({{ list | isLabel('NO_PLAY_LABEL') }})</el-radio>
      </el-radio-group>
    </div>

    <div class="questions scrollbarStyle">
      <!--<question-item-->
      <!--  v-for="(item, index) in questionList"-->
      <!--  :key="index"-->
      <!--  :value="item"-->
      <!--  @remove="handleRemove(index)"-->
      <!--  @updateList="$emit('update')"-->
      <!--/>-->

      <!--<ui-sortable-list>-->
      <!--  <ui-sortable-item-->
      <!--    v-for="(item, index) in questionList"-->
      <!--    :key="index"-->
      <!--  >-->
      <!--    <question-item-->
      <!--      :value="item"-->
      <!--      @remove="handleRemove(index)"-->
      <!--      @updateList="$emit('update')"-->
      <!--    />-->
      <!--  </ui-sortable-item>-->
      <!--</ui-sortable-list>-->
      <draggable :value="questionList" @input="e => sortList = e" :draggable="'.draggable'">
        <question-item
          v-for="(item, index) in questionList"
          :key="index.id"
          :value="item"
          :class="{ 'draggable': sortMode }"
          :hasChildMaterialFlag="hasChildMaterialFlag"
          @remove="handleRemove(index)"
          @updateList="$emit('update')"
        />
        <!--<transition-group>-->
        <!--</transition-group>-->
      </draggable>
      <div class="nomoredata">没有更多数据了~</div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { questionWordUpload, getQuestionListByClassHourId } from '@/api/branchImport.js'
import { questionSaveSortAll } from '@/api/recordQuestion'
import questionItem from './questionItem.vue'

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    pageNumber: {
      type: Number,
      default: 1
    },
    hasChildMaterialFlag: {
      type: Boolean,
      default: false
    }
  },
  components: {
    draggable,
    questionItem
  },
  data () {
    return {
      // 排序
      sortMode: false,
      sortList: [],

      filterParams: '',
      currentPage: 1
    }
  },
  watch: {
    list: {
      immediate: true,
      handler (val) {
        this.sortList = val || []
      }
    }
  },
  computed: {
    questionList () {
      const list = this.sortList
      // 排序模式显示所有数据
      if (this.sortMode) {
        return list
      }
      // 审核状态
      if (!this.filterParams) {
        // 1. 不限
        return this.list
      } else if (this.filterParams === 'NO_PLAY_LABEL') {
        // 2. 未打标签
        return this.list.filter(item => !item.isLabel)
      } else {
        // 3. 指定审核状态
        return this.list.filter(item => item.questionStatus === this.filterParams)
      }
    }
  },
  filters: {
    amount (list, status) {
      if (!list) return 0
      if (!status) return list.length
      return list.filter(item => item.questionStatus === status).length
    },
    isLabel (list, status) {
      if (!list) return 0
      if (!status) return list.length
      return list.filter(item => !item.isLabel).length
    }
  },
  mounted () {
    const element = document.querySelector('.questions')
    if (element) {
      element.addEventListener('scroll', this.getHeight)
      const scrollHeight = this.$store.state.branchImport.scrollHeight
      if (scrollHeight && scrollHeight > 1) {
        element.scrollTop = Number(scrollHeight)
      }
    }
  },
  activated () {
    const element = document.querySelector('.questions')
    if (element) {
      const scrollHeight = this.$store.state.branchImport.scrollHeight
      if (scrollHeight && scrollHeight > 0) {
        element.scrollTop = Number(scrollHeight)
      }
    }
  },
  beforeDestroy () {
    const element = document.querySelector('.questions')
    if (element) {
      element.removeEventListener('scroll', this.getHeight)
    }
  },
  methods: {
    // 记录滚动条位置
    getHeight (e) {
      const scrollHeight = e.target.scrollTop
      if (scrollHeight && scrollHeight > 1) {
        this.$store.commit('SET_SCROLL_HEIGHT', scrollHeight)
      } else {
        this.$store.commit('REMOVE_SCROLL_HEIGHT')
      }
    },
    // 排序操作
    handleSort (action = 'start') {
      if (action === 'start') { // 启用排序模式
        this.sortList = this.list || [] // 清理未提交排序状态
        this.drag = false
        this.sortMode = true
      } else if (action === 'submit') { // 提交排序结果
        this.$confirm('确认提交').then(this.onSort)
      }
    },

    // 更新排序
    onSort () {
      const params = this.sortList.map((m, index) => ({
        sort: index + 1,
        questionId: m.id,
        teachingMaterialId: m.teachingMaterialId,
      }))
      const loading = this.$loading({
        lock: true,
      })
      questionSaveSortAll(params)
        // .catch(() => ({ code: 0, msg: 'xxx' }))
        .then(res => {
          if (res.code === 0) {
            this.$message.success('完成')
            this.sortMode = false
            // 更新列表
            this.$emit('getUploadProgress', this.sortList)
          } else {
            this.$message.error(res.msg)
          }
        }).finally(() => {
          loading.close()
        })
    },

    handleRemove (index) {
      this.list.splice(index, 1)
    },
    // 上传文件之前的钩子
    beforeUpload (file) {
      const isWord = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['doc', 'docx']
      if (whiteList.indexOf(isWord) === -1) {
        return this.$message.error('上传文件仅支持 word 格式!')
      }
      return false
    },
    // 批量画坐标
    branchDrawCoord () {
      this.$router.push({
        path: '/batchDrawCoordinates',
        query: {
          pageNumber: this.pageNumber
        }
      })
    },
    // 批量纠错
    branchErrorCorrection () {
      this.$router.push({
        name: 'pictureRecognition',
        query: {
          type: 'edit'
        }
      })
    },
    handleCommand (command) {
      if (command === 'add') {
        document.getElementById('input-file-upload').getElementsByTagName('input')[0].click()
      } else if (command === 'img') {
        this.imageRecognition()
      }
    },
    // word导入
    questionWordUpload (file) {
      const loading = this.$loading({
        lock: true,
        text: '正在上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)'
      })
      questionWordUpload(file).then(res => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '上传成功'
          })
          this.updateList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(err => {
        this.$message({
          type: 'error',
          message: err
        })
      }).finally(() => {
        loading.close()
      })
    },

    // 根据课时id获取所有题目
    updateList () {
      const classHourId = this.$store.state.branchImport.searchData.classHourId
      const materialId = this.$store.state.branchImport.searchData.teachingMaterialId
      getQuestionListByClassHourId(classHourId, materialId).then(res => {
        if (res.code === 0) {
          const questionList = res.data
          this.$emit('getUploadProgress', questionList)
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(err => {
        this.$message({
          type: 'error',
          message: err
        })
      })
    },
    handleChange (file) {
      const f = file.raw
      // const standardTeachingMaterialId = this.$store.state.branchImport.searchData.editionGradeValue
      const teachingMaterialId = this.$store.state.branchImport.searchData.teachingMaterialId
      const classHourId = this.$store.state.branchImport.searchData.classHourId
      const fileData = new FormData()
      fileData.append('file', f)
      // fileData.append('standardTeachingMaterialId', standardTeachingMaterialId)
      fileData.append('teachingMaterialId', teachingMaterialId)
      fileData.append('classHourId', classHourId)
      this.questionWordUpload(fileData)
    },
    // 图片识别
    imageRecognition () {
      this.$router.push({
        path: '/pictureRecognition',
        query: {
          pageNumber: this.pageNumber
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page{
  width: 100%;
  height: 100%;
  .headerTop{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    .qns{
      font-size: 15px;
      font-weight: 600;
      color: #000;
    }
    .fun{
      display: flex;
      align-items: center;
    }

    .tip {
      margin-right: 10px;
      color: #999;
    }
    .el-button {
      width: 120px;
    }
  }
  .questions{
    //height: 83vh;
    margin: 0 -15px;
    padding: 0 15px;
    height: calc(100vh - 200px);
    overflow: auto;
  }
  .nomoredata{
    text-align: center;
    padding: 5px 0;
    font-size: 13px;
    color: #999;
  }
}
</style>
