<template>
  <div class="page">
    <div>
      <!-- <div class="leftBtn" :title="uploadtip">
        <el-upload
        class="upload-demo"
        action=""
        :multiple="false"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :disabled="disabled"
        :on-change="handleChange"
        accept=".doc,.docx">
        <div class="download">
          <el-button size="small" type="primary" :disabled="disabled">word导入</el-button>
        </div>
        </el-upload>
        <div>
          <a :href="url" title="点击下载word模板">下载模板</a>
        </div>
      </div> -->
      <div class="leftBtn sq" :title="sqtip">
        <div style="margin-right:20px">
          <a :href="url" title="点击下载word模板">下载模板</a>
        </div>
        <el-button size="small" type="primary" @click="imageRecognition" :disabled="disabled">图片识别</el-button>
      </div>
    </div>
  </div>
</template>

<script>

import { questionWordUpload, getQuestionListByClassHourId } from '@/api/branchImport.js'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    uploadtip: {
      type: String,
      default: ''
    },
    sqtip: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      url: process.env.VUE_APP_STATIC + '/docs/homeworkUploadTemplate.docx'
    }
  },
  mounted () {

  },
  methods: {
    // 上传文件之前的钩子
    beforeUpload (file) {
      const isWord = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['doc', 'docx']
      if (whiteList.indexOf(isWord) === -1) {
        return this.$message.error('上传文件仅支持 word 格式!')
      }
      return false
    },
    // word导入
    questionWordUpload (file) {
      const loading = this.$loading({
        lock: true,
        text: '正在上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)'
      })
      questionWordUpload(file).then(res => {
        if (res.code === 0) {
          this.$message({ type: 'success', message: '上传成功' })
          this.updateList()
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      }).finally(() => {
        loading.close()
      })
    },
    // 根据课时id获取所有题目
    updateList () {
      const classHourId = this.$store.state.branchImport.searchData.classHourId
      const materialId = this.$store.state.branchImport.searchData.teachingMaterialId
      getQuestionListByClassHourId(classHourId, materialId).then(res => {
        if (res.code === 0) {
          const questionList = res.data
          this.$emit('getUploadProgress', questionList)
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      })
    },
    handleChange (file) {
      const f = file.raw
      // const standardTeachingMaterialId = this.$store.state.branchImport.searchData.editionGradeValue
      const teachingMaterialId = this.$store.state.branchImport.searchData.teachingMaterialId
      const classHourId = this.$store.state.branchImport.searchData.classHourId
      const fileData = new FormData()
      fileData.append('file', f)
      // fileData.append('standardTeachingMaterialId', standardTeachingMaterialId)
      fileData.append('teachingMaterialId', teachingMaterialId)
      fileData.append('classHourId', classHourId)
      this.questionWordUpload(fileData)
    },
    // 图片识别
    imageRecognition () {
      this.$router.push({
        path: '/pictureRecognition'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .leftBtn{
    display: flex;
    align-items: center;
    a{
      color: #1266A7;
      text-decoration:underline;
      margin-left: 15px;
      font-weight: 600;
    }
  }
  .sq{
    margin-top: 30px;
  }
  .download{
    margin-right: 10px;
  }
}
/deep/ .el-button--primary{
  background-color: #18C2C2;
  border: 1px solid #18C2C2;
  letter-spacing: 1px;
  width: 150px;
}
</style>
