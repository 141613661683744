<template>
  <el-dialog
    title="打标签"
    width="560px"
    :center="false"
    :visible.sync="playVisible"
    v-loading="loading"
  >
    <el-form size="small" class="setting-form" label-width="6em" label-position="top">
      <div class="aiTagging">
        <el-button type="default" size="small" @click="handleTagging">AI标注 (体验版)</el-button>
        <el-tooltip class="item" effect="dark" placement="bottom-end">
          <template slot="content">
            <div style="width: 280px;">AI标注(体验版)是根据题干内容而输出的答案、解析、知识点等结果，不能保证每次结果都是100%准确，结果仅供参考，最终解释权归平台所有。</div>
          </template>
          <i class="el-icon-question" style="font-size: 18px;vertical-align: text-bottom;margin-left: 8px;color: #18C2C2;"></i>
        </el-tooltip>
      </div>

      <el-form-item label="选择题型:" class="form-item">
        <el-select v-model="formData.questionType" placeholder="请选择题型">
          <el-option
            v-for="item in questionTypeList"
            :key="item.questionType"
            :label="item.questionTypeName"
            :value="item.questionType">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="难易程度:" class="form-item">
        <el-radio-group v-model="formData.degreeOfDifficulty">
          <el-radio :label="item.id" v-for="item in degreeList" :key="item.id">{{ item.value }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <!--<el-form-item label="章节目录:" class="form-item">-->
      <!--  <div class="result-label">当前章节：</div>-->
      <!--  <div class="result-list">-->
      <!--    {{ chapterName }}-->
      <!--  </div>-->
      <!--</el-form-item>-->

      <div v-if="!isCombination">
        <el-form-item label="选择知识点:" class="form-item">
          <SelectKnowledge
            v-model="formData.knowledge"
            :subsectionSubjectId="$store.state.branchImport.searchData.subjectId"
            :options="knowledgeOptions"
          />
        </el-form-item>

        <el-form-item label="选择核心素养（非必需）:" class="form-item">
          <SelectKeyAccomplishment
            v-model="formData.keyAccomplishment"
            :options="keyAccomplishmentOptions"
          />
        </el-form-item>
      </div>

      <div class="subtopic" v-for="(item, index) in subtopicData" :key="item.questionId" v-show="!item.isDelete">
        <div class="subtopic-hd">第{{ index + 1 }}小题</div>
        <el-form-item label="选择知识点:" class="form-item">
          <SelectKnowledge
            v-model="item.knowledge"
            :subsectionSubjectId="$store.state.branchImport.searchData.subjectId"
            :options="knowledgeOptions"
          />
        </el-form-item>

        <el-form-item label="选择核心素养（非必需）:" class="form-item">
          <SelectKeyAccomplishment
            v-model="item.keyAccomplishment"
            :options="keyAccomplishmentOptions"
          />
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button style="width: 80px" @click="playCancel">取 消</el-button>
      <el-button style="width: 80px" type="primary" @click="playOk">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Tooltip } from 'element-ui'
import { degree } from '@/utils/enums.js'
import { getStandardTeachingMaterialId } from '@/api/question-bank'
import { getKeyAccomplishmentList } from '@/api/keyAccomplishment'
import { getKnowledgePointList } from '@/api/knowledge'
import { questionEdit } from '@/api/recordQuestion'
import SelectKeyAccomplishment from './SelectKeyAccomplishment'
import SelectKnowledge from './SelectKnowledge'
import { aiTagging } from '@/api/ai'
import { Difficulty } from '@/config/enums/Difficulty'

export default {
  name: 'playLabel',
  components: {
    'el-tooltip': Tooltip,
    SelectKeyAccomplishment,
    SelectKnowledge,
  },
  data () {
    return {
      loading: false,
      playVisible: false,
      questionTypeList: [], // 题型列表
      degreeList: degree, // 难易程度列表
      chapterName: null,

      isCombination: false, // 大小题标记
      formData: {
        questionType: null, // 题型
        degreeOfDifficulty: null, // 难易程度
        knowledge: [],
        keyAccomplishment: [],
      },
      subtopicData: [], // 小题属性

      // knowledgeData: [], // 选中节点
      knowledgeOptions: null, // 选项
      // knowledgeVisible: false,

      keyAccomplishmentOptions: null,
      info: null
    }
  },
  methods: {
    show (item) {
      this.info = item
      this.isCombination = item.isCombination
      this.formData.questionType = item.questionType
      this.formData.degreeOfDifficulty = item.degreeOfDifficulty
      this.formData.knowledge = item.knowledge || []
      this.formData.keyAccomplishment = item.keyAccomplishment || []
      this.subtopicData = item.subtopicQuestion ? item.subtopicQuestion.map(sub => {
        return {
          chapter: sub.chapter,
          questionId: sub.questionId,
          knowledge: sub.knowledge || [],
          keyAccomplishment: sub.keyAccomplishment || [],
        }
      }) : []
      const searchData = this.$store.state.branchImport.searchData
      if (searchData) {
        this.chapterName = searchData.chapterName
        this.getStandardTeachingMaterialId(searchData.editionGradeValue)
        this.loadKnowledgePointList(searchData.subjectId)
        this.loadKeyAccomplishmentList(searchData.subjectId)
      }
      this.loading = false
      this.playVisible = true
    },
    playOk () {
      const params = Object.assign({
        titleSource: 'TEACHING_MATERIAL',
        questionId: this.info.id,
        isCombination: this.isCombination,
        titleNumber: this.info.titleNumber,
        stem: this.info.stem,
        answer: this.info.answer,
        analysis: this.info.analysis,
        teachingMaterialId: this.info.teachingMaterialId,
        // standardTeachingMaterialId: this.info.standardTeachingMaterialId,
        grade: this.info.grade,
        classHourId: this.info.classHourId,
        subsectionId: this.info.subsectionId,
        subject: this.info.subject,
        coordinateInfo: this.info.coordinate,
        chapter: this.info.chapter,
        audioResource: this.info.audioResource,
        videoResource: { url: this.info.videoResource?.url || '' }
      }, this.formData)
      if (this.isCombination) {
        params.subtopicQuestion = this.info.subtopicQuestion.map((subtopic, idx) => {
          const data = this.subtopicData[idx]
          return Object.assign({
            questionId: subtopic.questionId,
            titleNumber: subtopic.titleNumber,
            stem: subtopic.stem,
            answer: subtopic.answer,
            analysis: subtopic.analysis,
            coordinate: subtopic.coordinate,
            sort: subtopic.sort,
            isDelete: false,
          }, data)
        })
      }
      if (this.estimatedTime) {
        params.estimatedTime = this.estimatedTime
      }
      this.loading = true
      questionEdit(params).finally(() => {
        this.loading = false
      }).then(res => {
        if (res.code === 0) {
          this.playVisible = false
          this.$message.success('操作成功')
          this.$emit('change')
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('接口异常，打标签失败')
      })
    },
    playCancel () {
      this.playVisible = false
    },
    // 获取题型列表
    getStandardTeachingMaterialId (standardTeachingMaterialId) {
      getStandardTeachingMaterialId(standardTeachingMaterialId).then(res => {
        if (res.code === 0) {
          this.questionTypeList = res.data
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      })
    },
    // 知识点列表
    loadKnowledgePointList (subjectId) {
      return getKnowledgePointList(subjectId).then(res => {
        if (res.code === 0) {
          console.log('--- ', res.data)
          const list = this.disabledKnowData(res.data)
          this.knowledgeOptions = list
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 处理知识点数据,非最后一层级都不可选中
    disabledKnowData (list) {
      list.forEach(item => {
        if (item.children && item.children.length > 0) {
          item.disabled = true
          this.disabledKnowData(item.children)
        }
      })
      return list
    },
    // 加载核心素养列表
    loadKeyAccomplishmentList (subsectionId) {
      return getKeyAccomplishmentList(subsectionId).then(res => {
        if (res.code === 0) {
          this.keyAccomplishmentOptions = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // AI标注
    handleTagging () {
      const question = this.info
      if (!question.stem) {
        this.$message.error('缺少题干')
        return
      }

      // 题干组合成接口参数
      const stemTransform = () => {
        if (!question.isCombination) return question.stem
        const list = (question.subtopicQuestion || [])
          .map((m, idx) => ` ${idx + 1}、${m.stem}`)
        return `${question.stem}${list.join('')}`
      }

      const data = {
        isCombination: question.isCombination,
        image: [],
        subsectionId: question.subsectionId,
        stem: stemTransform(),
      }
      console.log(data)
      this.loading = true
      this.estimatedTime = undefined
      return aiTagging(data)
        .finally(() => { this.loading = false })
        .then(res => {
          if (res.code === 0) {
            const data = res.data

            // 时长 data.time: '3分4秒'
            if (data.time) {
              const match = data.time.trim().match(/((\d+)分)?((\d+)秒)?/)
              this.estimatedTime = (match[2] ? parseInt(match[2]) : 0) * 60 + (match[4] ? parseInt(match[4]) : 0)
            }

            // 难易度 （以 0-4 表示）
            if (data.difficulty >= 0 && data.difficulty < Difficulty.length) {
              this.formData.degreeOfDifficulty = Difficulty[data.difficulty].value
            }

            // 知识点: knowledge_path 代表单个知识点的层级列表，实际仅一个选中项
            if (data.knowledge_path && data.knowledge_path.length) {
              const knowledge = [{ knowledgeId: data.knowledge_path[0] }]
              if (question.isCombination) {
                this.subtopicData.forEach(item => {
                  item.knowledge = knowledge
                })
              } else {
                this.formData.knowledge = knowledge
              }
            }

            // 核心素养
            if (data.literacy && data.literacy.length) {
              const keyAccomplishment = data.literacy.map(m => ({ keyAccomplishmentId: m }))
              if (question.isCombination) {
                this.subtopicData.forEach(item => {
                  item.keyAccomplishment = [...keyAccomplishment]
                })
              } else {
                this.formData.keyAccomplishment = keyAccomplishment
              }
            }

            // // 答案&解析 - 打标签功能不修改题目内容
            // if (question.isCombination) {
            //   if (data.answer) {
            //     const answerList = parseQuestionContent(data.answer)
            //     this.subtopicData.forEach((item, index) => {
            //       const value = answerList[index]
            //       if (value) {
            //         item.answer = value
            //       }
            //     })
            //   }
            //   if (data.analysis) {
            //     const analysisList = parseQuestionContent(data.analysis)
            //     this.subtopicData.forEach((item, index) => {
            //       const value = analysisList[index]
            //       if (value) {
            //         item.analysis = value
            //       }
            //     })
            //   }
            // } else {
            //   if (data.answer) {
            //     this.formData.answer = data.answer
            //   }
            //   if (data.analysis) {
            //     this.formData.analysis = data.analysis
            //   }
            // }
          }
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.item-box{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .item-title{
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }
}
.item{
  align-items: flex-start;
}

.form-item{
  font-weight: 600;
}

.setting-form /deep/ {
  position: relative;
  padding: 0px 30px;
  max-height: calc(80vh - 240px);
  overflow: auto;

  .el-form-item__label {
    color: #000;
  }
}

.dlg-main {
  max-height: calc(60vh);
  overflow: auto;

  /deep/ .el-tree-node__children {
    overflow: visible;
  }
}
.dlg-footer {
  text-align: right;
  margin: 10px 0 -10px;
}

.subtopic {
  position: relative;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #eee;
  border-radius: 4px;

  &-hd {
    margin-top: 5px;
    color: #000;
  }

  .el-form-item {
    margin-left: 2em;
  }
}

.result {
  &-label {
    float: left;
  }

  &-list {
    margin-left: 50px;
    // line height: 32px;
    padding: 4px 0;
    line-height: 24px;
    color: #999;
  }
}
.result-list{
  font-weight: 400;
  color: #666666;
}

.aiTagging {
  position: relative;
  z-index: 50;
  text-align: right;
  height: 0;
}
</style>
