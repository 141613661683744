/* eslint-disable */
import request from '@/utils/request'

/**
 * AI题目标注
 * @param {Object} data
 * @param {Boolean} data.isCombination 是否组合题
 * @param {string} data.stem 题干
 * @param {number} data.subsectionId 学段科目ID
 * @param {Array} data.image 题干内图片，题目截图列表
 * @returns {AxiosPromise}
 */
export function aiTagging (data) {
  return request({
    timeout: 60000,
    method: 'POST',
    url: '/ai/tagging',
    data
  })
}
