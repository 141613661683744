<template>
  <div style="margin-bottom: 10px">
    <div class="main" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="审核中">
      <div class="mainInfo">
        <div class="tagsWrapper">
          <span class="tag off" v-if="value.dataStatus === 'OFF_THE_SHELF'">已下架</span>
          <el-tooltip v-if="value.questionStatus === 'AUDIT_FAILED'" effect="dark" :content="value.failReason" placement="top">
            <span class="tag af">审核失败 <i class="el-icon-warning-outline"></i></span>
          </el-tooltip>
          <span class="tag tbp" v-if="value.questionStatus === 'TO_BE_PROCESSED'">待审核</span>
          <span class="tag" v-if="hasCoordinate">已画坐标</span>
          <span class="tag highlight" v-else>未画坐标</span>
          <span class="tag" v-if="value.isLabel">已打标签</span>
          <span class="tag highlight" v-else>未打标签</span>
        </div>
        <div class="stem-item">
          <div class="titleNumber" v-if="value.titleNumber">{{ value.titleNumber }}</div>
          <mathpad v-if="value.stem" :value="value.stem" />
          <template v-if="value.isCombination">
            <div class="subtopic" v-for="(item, index) in value.subtopicQuestion" :key="index">
              <div class="titleNumber">({{ index + 1 }})</div>
              <mathpad v-if="item.stem" :value="item.stem" />
            </div>
          </template>
        </div>
      </div>
      <div class="diffQuestionType">
        <div class="top">
          <div class="items">
            <div v-if="value.degreeOfDifficulty">难度：{{ value.degreeOfDifficulty | getDiffName }}</div>
            <div v-else>难度：-</div>
            <div>题型：{{ value.questionTypeName ? value.questionTypeName : '-' }}</div>
            <template v-if="value.authorInfo">
              <div>录题：{{ value.authorInfo.recorderName || '-' }}</div>
            </template>
            <template v-if="value.level && value.level.length">
              <div>审核：{{ value.level[0].auditName || '-' }}</div>
            </template>
          </div>
          <div class="items menus">
            <ui-audio
              v-if="(value.audioResource && value.audioResource.url) || value.resource"
              type="text"
              :src="value.audioResource.url"
              :text="value.resource"
            />
            <div @click="showAnalysis">{{ isShowAnalysis ? '收起解析' : '查看解析' }}</div>
            <div @click="playLabel">打标签</div>
            <!--<div @click="playLabel" v-show="!value.reviseFlag">打标签</div>-->
            <div @click="errorCorrection" v-show="!value.reviseFlag || !hasChildMaterialFlag">纠错</div>
            <!--<div @click="drawCoordinates">画坐标</div>-->
            <div v-if="value.questionStatus !== 'COMPLETED'" @click="handleDelete" v-show="!value.reviseFlag">删除</div>
            <div v-if="value.questionStatus === 'TO_BE_PROCESSED'" @click="aduitBank">审核</div>
          </div>
        </div>
        <!-- 默认题目解析 -->
        <default-analysis v-show="isShowAnalysis && !value.isCombination" :value="value" />
        <!-- 大小题解析 -->
        <combination-analysis v-show="isShowAnalysis && value.isCombination" :value="value" />
      </div>
    </div>
    <el-dialog
      title="审核"
      top="30vh"
      :visible.sync="dialogVisible"
      width="30%">
      <div>
        <el-radio-group v-model="auditStatus">
          <el-radio label="AUDIT_FAILED">审核未通过</el-radio>
          <el-radio label="COMPLETED">审核通过</el-radio>
        </el-radio-group>
        <el-input
          style="margin-top:20px"
          type="textarea"
          v-if="auditStatus==='AUDIT_FAILED'"
          :rows="5"
          placeholder="请填写未通过审核的原因"
          v-model="auditFailMsg">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button style="width: 80px" @click="aduitCancel">取 消</el-button>
        <el-button style="width: 80px" type="primary" @click="aduitok">确 定</el-button>
      </span>
    </el-dialog>
    <template>
      <play-label ref="playLabelComponent" @change="$emit('updateList')" />
    </template>
  </div>
</template>

<script>
import { Tooltip } from 'element-ui'
import mathpad from './Mathpad.vue'
import playLabel from '../editor/playLabel.vue'
import { degree } from '@/utils/enums.js'
import { recordQuestionAduit } from '@/api/branchImport.js'
import { questionRemove } from '@/api/recordQuestion'
import defaultAnalysis from './defaultAnalysis.vue'
import combinationAnalysis from './combinationAnalysis.vue'

/**
 * 题目操作限制说明
 * - hasChildMaterialFlag: true,  reviseFlag: true  题目不可以打标签、删除、审核、编辑
 * - hasChildMaterialFlag: true,  reviseFlag: false 题目不做任何限制
 * - hasChildMaterialFlag: false, reviseFlag: true  题目不可以打标签、删除、审核，可以编辑
 * - hasChildMaterialFlag: false, reviseFlag: false 题目不做任何限制
 */
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    hasChildMaterialFlag: { // 教材是否有修订版
      type: Boolean,
      default: false,
    }
  },
  components: {
    mathpad,
    'el-tooltip': Tooltip,
    playLabel,
    defaultAnalysis,
    combinationAnalysis
  },
  data () {
    return {
      isShowAnalysis: false,
      dialogVisible: false,
      auditStatus: 'COMPLETED',
      auditFailMsg: '',
      fullscreenLoading: false
    }
  },
  computed: {
    // 是否已画坐标
    hasCoordinate () {
      const q = this.value
      if (q && q.coordinate && q.coordinate.length) {
        // 组合题验证小题坐标完整
        if (q.isCombination) {
          const s = q.subtopicQuestion
          for (let i = 0; i < s.length; i++) {
            const c = s[i].coordinate
            if (!c || (c.length === 0)) {
              return false
            }
          }
        }
        return true
      }
      return false
    },
  },
  watch: {
    dialogVisible (newVal) {
      if (!newVal) {
        this.auditStatus = 'COMPLETED'
        this.auditFailMsg = ''
      }
    }
  },
  mounted () {

  },
  methods: {
    // 查看解析
    showAnalysis () {
      this.isShowAnalysis = !this.isShowAnalysis
    },
    // 打标签
    playLabel () {
      this.$refs.playLabelComponent.show(this.value)
    },
    // 纠错
    errorCorrection () {
      this.$router.push({
        name: 'pictureRecognition',
        // params: {
        //   questionId: this.value.id
        // },
        query: {
          type: 'edit',
          questionId: this.value.id,
        }
      })
    },
    // 画坐标
    drawCoordinates () {
      this.$router.push({
        name: 'BatchDrawCoordinates',
        // path: '/batchDrawCoordinates'
        params: {
          questionId: this.value.id
        }
      })
    },
    // 审核
    aduitBank () {
      this.dialogVisible = true
    },
    aduitok () {
      if (!this.auditFailMsg && this.auditStatus === 'AUDIT_FAILED') {
        return this.$message.error('审核未通过 原因不能为空')
      }
      this.fullscreenLoading = true
      const params = {
        questionBankId: this.value.id,
        questionStatus: this.auditStatus,
        failReason: this.auditFailMsg
      }
      recordQuestionAduit(params).then(res => {
        if (res.code === 0) {
          this.$message.success('审核完成')
          this.$emit('updateList')
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('接口异常，审核失败')
      }).finally(() => {
        this.dialogVisible = false
        this.auditStatus = 'COMPLETED'
        this.auditFailMsg = ''
        this.fullscreenLoading = false
      })
    },
    aduitCancel () {
      this.auditStatus = 'COMPLETED'
      this.auditFailMsg = ''
      this.dialogVisible = false
    },

    // 删除题目(仅限未审核未打标签）
    handleDelete () {
      this.fullscreenLoading = true
      this.$confirm('确认删除').then(() => {
        questionRemove(this.value.id).then(res => {
          if (res.code === 0) {
            this.$message.success('完成')
            this.$emit('updateList')
          } else {
            this.$message.error(res.msg)
          }
        })
      }).finally(() => {
        this.fullscreenLoading = false
      })
    }
  },
  filters: {
    getDiffName (val) {
      let name = ''
      degree.forEach(item => {
        if (item.id === val) {
          name = item.value
        }
      })
      return name
    }
  }
}
</script>

<style lang="scss" scoped>
.draggable {
  cursor: grab;

  .mainInfo .stem-item {
    margin-bottom: 0;
    max-height: 4em;
    overflow: hidden;
  }

  .tagsWrapper,
  .diffQuestionType {
    display: none;
  }
}

.main{
  border: 1px solid rgba(153,153,153,0.2);
  border-radius: 4px;
}
.main:hover{
  border: 1px solid #18C2C2;
}
.mainInfo{
  padding: 15px;

  .tag {
    display: inline-block;
    font-size: 12px;
    padding: 1px 10px;
    margin: 0 10px 5px 0;
    background-color: #18C2C2;
    border-radius: 2px;
    color: #fff;

    &.highlight {
      background-color: #FF5500;
    }
  }
  .off{
    background-color: #ffa600;
  }
  .af{
    background-color: #f91b0a;
  }
  .tbp{
    background-color: #2DB7F5;
  }
  .stem-item{
    margin-bottom: 15px;
    line-height: 20px;
  }
}
.diffQuestionType{
  font-size: 14px;
  font-weight: 600;
  color: #000;
  background-color: #E7F9F9;
  padding: 15px 20px;
  .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.items{
  display: flex;
  align-items: center;
  div {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}
.menus{
  div{
    color: #18C2C2;
    cursor: pointer;
  }
}
/deep/ .el-button--primary{
  background-color: #18C2C2;
  border: 1px solid #18C2C2;
  letter-spacing: 1px;
  width: 150px;
}

// 题号
.titleNumber {
  float: left;
  vertical-align: bottom;
  color: #999;
}

// 小题
.subtopic {
  margin-top: 5px;

  .titleNumber {
    margin: 0 5px 0 30px;
    min-width: 16px;
  }
}

.ui-mathpad {
  margin-left: 30px;
}

/deep/ .ui-audio {
  .el-button {
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
